import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../Admin/components/Loading/Loadable";
import { path } from "../constant/path";
const HomePage = Loadable(lazy(() => import("../Home/pages/HomePage")));
const KQXNPage = Loadable(lazy(() => import("../Home/pages/KQXNPage/Login")));
const CategoryPage = Loadable(lazy(() => import("../Home/pages/CategoryPage")));
const SearchPage = Loadable(lazy(() => import("../Home/pages/SearchPage")));
const CategoryVideo = Loadable(
  lazy(() => import("../Home/pages/CategoryPage/CategoryVideo"))
);
const LoginAdmin = Loadable(lazy(() => import("../Admin/pages/LoginPage")));
const VideoPage = Loadable(lazy(() => import("../Home/pages/VideoPage")));
const DetailPage = Loadable(lazy(() => import("../Home/pages/DetailPage")));
const AdminPage = Loadable(lazy(() => import("../Admin/pages/AdminPage")));
const MainRoutesFunctional = ({ isAuthenticated, setIsAuthenticated }: any) => {
  const MainRoutes: any = [
    {
      path: path.HOME_PATH,
      element: <HomePage />,
    },
    {
      path: path.KQXN_PATH,
      element: <KQXNPage />,
    },
    {
      path: `${path.CATEGORY_PATH}/:categoryId`,
      element: <CategoryPage />,
    },
    {
      path: `${path.CATEGORY_PATH}/video/:categoryId`,
      element: <CategoryVideo />,
    },
    {
      path: `${path.POST_PATH}/:id`,
      element: <DetailPage />,
    },
    {
      path: `${path.SEARCH_PATH}/:keyword`,
      element: <SearchPage />,
    },
    {
      path: `${path.POST_PATH}/video/:id`,
      element: <VideoPage />,
    },
    {
      path: path.LOGIN_PATH,
      element: <LoginAdmin setIsAuthenticated={setIsAuthenticated} />,
    },
    {
      path: `/${path.ADMIN_PATH}/:name`,
      element: isAuthenticated ? (
        <AdminPage
          isAuthenticated={isAuthenticated}
          setIsAuthenticated={setIsAuthenticated}
        />
      ) : (
        <Navigate to={`/${path.LOGIN_PATH}`} replace={true} />
      ),
    },
    {
      path: `/${path.ADMIN_PATH}`,
      element: isAuthenticated ? (
        <AdminPage
          isAuthenticated={isAuthenticated}
          setIsAuthenticated={setIsAuthenticated}
        />
      ) : (
        <Navigate to={`/${path.LOGIN_PATH}`} replace={true} />
      ),
    },
  ];
  return MainRoutes;
};

export default MainRoutesFunctional;
