import { useEffect, useState } from "react";
import {
  checkTokenValidity,
  setToken,
} from "./Admin/utils/checkTokenSesionStorage";
import "./App.css";
import ScrollToTop from "./Home/components/ScrollToTop";
import Routes from "./routes";
import { ToastContainer } from "react-toastify";
import LoginAdmin from "./Admin/pages/LoginPage";
function App() {
  const token = sessionStorage.getItem("token") ?? "";
  const jwTokenExpires = sessionStorage.getItem("jwTokenExpires") ?? "";
  const [isAuthenticatedApp, setIsAuthenticatedApp] = useState(!!token);
  useEffect(() => {
    const checkTokenStatus = () => {
      // Kiểm tra nếu token còn hợp lệ
      const isValid = checkTokenValidity();

      // Phân tích ngày hết hạn
      const expirationDate = new Date(jwTokenExpires);

      // Lấy ngày hiện tại
      const currentDate = new Date();

      // Nếu token đã hết hạn hoặc không hợp lệ, đặt xác thực thành false
      if (!isValid || expirationDate <= currentDate) {
        setIsAuthenticatedApp(false);
      } else {
        setToken(token);
      }
    };

    checkTokenStatus();

    // Xóa timeout khi component unmount
  }, [token, jwTokenExpires]);

  useEffect(() => {
    if (token) {
      setIsAuthenticatedApp(true);
    } else {
      setIsAuthenticatedApp(false);
    }
  }, [token]);
  return (
    <body className="App">
      <ScrollToTop />

      <Routes
        setIsAuthenticated={setIsAuthenticatedApp}
        isAuthenticated={isAuthenticatedApp}
      />
      <ToastContainer />
    </body>
  );
}

export default App;
