import { toast } from "react-toastify";

export const filterBySortOrder = (data: any[], sortOrder: number) =>
  data.find((item: any) => item.sortOrder === sortOrder) || null;

export const extractImageSrc = (dataArray: any, key: any) => {
  dataArray.forEach((item: any) => {
    const match = item[key]?.match(/src='(.*?)'/);
    if (match) {
      item[key] = match[1];
    }
  });
};
export function showWarningToast(message: any) {
  toast.warning(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export function showSucesssToast(message: any) {
  toast.success(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export function showErrorToast(message: any) {
  toast.error(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export const decodeJwt = (token: string): any => {
  if (!token) return null;
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join("")
  );

  return JSON.parse(jsonPayload);
};
